import Layout from "../components/layout";
import { Link } from "gatsby";

import React, { Component } from "react";
import e1 from "../images/events/1.jpg";
import e2 from "../images/events/2.jpg";
import e3 from "../images/events/3_s.jpg";
import e4 from "../images/events/4.jpeg";
import e5 from "../images/events/5.jpg";
import e6 from "../images/events/6.jpg";
import e7 from "../images/events/7.jpg";
import e8 from "../images/events/8.jpg";
import e9 from "../images/events/9.jpg";
import e10 from "../images/events/10.jpg";
import e11 from "../images/events/11.jpg";
import e12 from "../images/events/12.jpg";
import e13 from "../images/events/13.jpg";
import e14 from "../images/events/14.jpg";
import e15 from "../images/events/15.jpg";
import e16 from "../images/events/16.jpg";
import e17 from "../images/events/17.jpeg";
import e18 from "../images/events/18.png";
import e19 from "../images/events/19.jpg";
import e20 from "../images/events/20.jpg";
import e21 from "../images/events/21.jpg";
import e22 from "../images/events/22.jpg";
import e23 from "../images/events/23.jpg";
import e24 from "../images/events/24.jpg";
import e25 from "../images/events/25.jpg";

import Carousel, { ModalGateway, Modal } from "react-images";

class EventsGallery extends Component {

  constructor (props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  componentDidMount () {

  }

  openLightBox (index) {
    this.setState({
      isOpen: true,
      photoIndex: index
    });

  }

  render () {
    const data = [
      {
        image: e1,
        text: "CAP Cohort students at Explore Hub, Chitkara University, Himachal Pradesh."
      },
      {
        image: e2,
        text: "CAP Cohort students with honourable Pro Chancellor Dr. Madhu Chitkara."
      },
      {
        image: e3,
        text: "Mr. Sumeer Walia, Director CEED, delivering Master class to CAP Cohort students."
      },
      {
        image: e4,
        text: "MoU Signing between Natio Cultus and Chitkara University."
      },
      {
        image: e5,
        text: "CAP Cohort students with honourable Vice Chancellor Dr. Varinder Kanwar."
      },
      {
        image: e6,
        text: "CAP Cohort students witnessed “A day with Golf”."
      },
      {
        image: e7,
        text: "Dr. KDS Bedi, Principal, CSSM delivering session on “How to perform Sales & Marketing effectively”."
      },
      {
        image: e8,
        text: "Mr. Ajay, Expert Digital Marketing, delivering expert lecture on Digital Marketing to CAP Cohort students."
      },
      {
        image: e9,
        text: "Dr. Varinder Kanwar, honourable Vice Chancellor, Chitkara University, Himachal Pradesh interacting with CAP Cohort participants."
      },
      {
        image: e10,
        text: " Ms. Abha Sharma, Mentor CEED interacting with CAP Cohort participants."
      },
      {
        image: e11,
        text: "Students pitching their ideas in front of CEED mentors."
      },
      {
        image: e12,
        text: " Students pitching their ideas in front of CEED mentors."
      },
      {
        image: e13,
        text: " Query solving round with Dr. Varinder Kanwar, honourable Vice Chancellor, Chitkara University, Himachal Pradesh."
      },
      {
        image: e14,
        text: "CAP Cohort students with Founders of “Ingenious Faces Mr. Apoorva Bamba and Ms. Vidushi Malhotra."
      },
      {
        image: e15,
        text: "Live idea pitching session in front of honourable Pro Chancellor Dr. Madhu Chitkara."
      },
      {
        image: e16,
        text: "Mr. Nalin Singh, Founder Natio Cultus interviewing students for the selection in CAP course."
      },
      {
        image: e17,
        text: "Startups after raising funding of 1.5 Cr at India Fund Fest, Chandigarh."
      },
      {
        image: e18,
        text: "Ms. Vidushi, Co-Founder “Ingenious Faces” delivering workshop on design thinking."
      },
      {
        image: e19,
        text: "Students learning Entrepreneurship Skills from team “Ingenious Faces”"
      },
      {
        image: e20,
        text: "CAP students pitching their startup ideas to Mr. Vineet Khurana, Vice President, Chandigarh Angels Network."
      },
      {
        image: e21,
        text: "Dr. Amit Vashishth teaching students about the art of hosting.\n"
      },
      {
        image: e22,
        text: "Mr. Chanpreet Singh an expert on prototyping introduced students to the very first stage of product development i.e. prototyping"
      },
      {
        image: e23,
        text: " Mr. Nalin Singh, Founder Natio Cultus teaching team building skills to CAP participants."
      },
      {
        image: e24,
        text: "One to One mentorship from sector specific mentors to CAP Students."
      },
      {
        image: e25,
        text: "Mr. Apoorav Bamba, Founder “Ingenious Faces” delivering workshop on design thinking."
      }

    ];
    const { photoIndex, isOpen } = this.state;
    return (
      <Layout>
        <section id="section-page2" className="section general-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8  text-center">
                <div className="page-heading">
                  <h2 className="display-4">Events Gallery</h2>

                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item"><Link to={"/"}>Home </Link></li>
                    <li className="list-inline-item"><a>/</a></li>
                    <li className="list-inline-item"><a href="#" className="text-muted">Gallery</a></li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section gallery">
          <div className="container">
            <div className="images">
              <div className="row gutter">
                {
                  data.map((item, index) => {
                    return (
                      <div key={index} className="col-lg-6 column">
                        <a className="image"
                           style={{ backgroundImage: "url(" + data[index].image + ")" }}
                           onClick={this.openLightBox.bind(this, index)}/>
                        <p>{data[index].text}</p>
                      </div>
                    );
                  })
                }
              </div>
            </div>
            <ModalGateway>
              {isOpen ? (
                <Modal onClose={() => this.setState({ isOpen: false })}>
                  <Carousel currentIndex={photoIndex} views={data.map(({image}) => {
                    return {
                      source : image
                    };
                  })}/>
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </section>
      </Layout>
    );
  }
}

export default EventsGallery;

